<template>
  <div class="login-wrap">
    <div class="i-login-back-head">
      <div class="i-back-head-img"></div>
      <span class="i-back-head-text">登录成功</span>
      <div class="i-back-head-right-text"></div>
    </div>
    <div class="login-success">
      <div class="login-success-img-box">
        <img src="../../assets/static/icon_finish.f9f37c60.svg" alt="" />
      </div>
      <div class="login-success-text">
        登录成功，可返回客户端进行操作
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginSuccess",
  data() {
    return {}
  },
  methods: {}
};
</script>
<style scoped>
.loginSuccess {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>